import Http from "./Http";
import { IFilter } from "../interfaces/IFilter";
import { ILoginForm, IPasswordReset } from "../interfaces/IUser";
const http = new Http();

class UserService {
  static getUsers(filter: IFilter) {
    return http.get<any>({
      endpoint: "/user/",
      payload: { params: filter },
    });
  }

  static registerUser(userData: FormData) {
    return http.post<any>({
      endpoint: "/user/register/",
      payload: userData,
    });
  }

  static login(args: ILoginForm) {
    return http.post<any>({
      endpoint: `/auth/login/`,
      payload: args,
    });
  }

  static getCurrentUser() {
    return http.get<any>({
      endpoint: "/user/my-detail/",
    });
  }

  static getProfile(username: string) {
    return http.get({
      endpoint: `/user/${username}/`,
    });
  }

  static updateProfile(username: string, userData: FormData) {
    return http.patch({
      endpoint: `/user/${username}/`,
      payload: userData,
    });
  }

  static resetPassword(userData: IPasswordReset) {
    return http.post({
      endpoint: "/user/password-reset/",
      payload: userData,
    });
  }
}

export default UserService;
