import React, { createContext, useState, useContext, ReactChild } from "react";

const GradesContext = createContext({});

interface IProps {
  children: ReactChild;
}

export const GradeContextProvider = ({ children }: IProps) => {
  const [grades, setGrades] = useState<any[]>([]);

  const setGradeValues = (args: any) => {
    setGrades(args);
  };

  return (
    <GradesContext.Provider
      value={{
        grades,
        setGradeValues,
      }}
    >
      {children}
    </GradesContext.Provider>
  );
};

export const useGradesContext: any = () => useContext(GradesContext);
