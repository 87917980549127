export const LANDING = "/";
export const NOTFOUND = "/not-found";
export const LOGIN = "/login";
export const PROFILE = "/my-profile";
export const EDITPROFILE = (uuid: string) => `/profile/${uuid}/edit/`;
export const COURSES = "/courses";
export const ADDCOURSE = "/course/add/";
export const EDITCOURSE = (uuid: string) => `/course/${uuid}/edit/`;
export const STUDENTS = "/students/";
export const ADDSTUDENT = "/student/add/";
export const STUDENTDETAIL = (uuid: string) => `/student/${uuid}/`;
export const EDITSTUDENT = (uuid: string) => `/student/${uuid}/edit/`;
export const TEACHERS = "/teachers/";
export const ADDTEACHER = "/teacher/add/";
export const TEACHERDETAIL = (uuid: string) => `/teacher/${uuid}/`;
export const EDITTEACHER = (uuid: string) => `/teacher/${uuid}/edit/`;
export const GRADES = "/grades/";
export const ADDGRADE = "/grade/add/";
export const SECTIONS = "/sections/";
export const ADDSECTION = "/section/add/";
export const GRADEROUTINE = (uuid: string) => `/grade/${uuid}/routine/`;
export const ADDGRADEROUTINE = (uuid: string) => `/grade/${uuid}/routine/add/`;
export const EDITROUTINE = (uuid: string, routineUuid: string) =>
  `/grade/${uuid}/routine/${routineUuid}/edit/`;
export const ADDEXAMROUTINE = (uuid: string) =>
  `/grade/${uuid}/exam-routine/add/`;
export const EDITEXAMROUTINE = (uuid: string, routineUuid: string) =>
  `/grade/${uuid}/exam-routine/${routineUuid}/edit/`;
export const EXAMROUTINE = (uuid: string) => `/grade/${uuid}/exam-routine/`;
export const BILLS = "/bill/";
export const LEAVE = `/leave/`;
export const EVENTS = "/event/";
export const ADDEVENT = `/event/add/`;
export const EDITEVENT = (uuid: string) => `/event/${uuid}/edit/`;
export const ACADEMICSESSION = "/academic-session/";
export const EXAMTERM = `/exam-term/`;
export const ADDEXAMTERM = `/exam-term/add/`;
export const ATTENDANCE = "/attendance/";
export const CLASSATTENDANCE = (uuid: string) => `/grade/${uuid}/attendance/`;
export const ASSIGNMENT = "/assignment/";
export const GENRE = "/library/genre/";
export const BOOK = "/library/book/";
export const BOOKADD = "/library/book/add/";
export const BORROWERSLIST = (uuid: string) =>
  `/library/book/${uuid}/borrowers/`;
export const BOOKEDIT = (uuid: string) => `/library/book/${uuid}/edit/`;
export const BOOKLEND = "/library/book/lend/";
export const BOOKRETURN = "/library/book/return/";
export const NOTICE = "/notice/";
export const NOTICEADD = "/notice/add/";
export const NOTICEDETAIL = (uuid: string) => `/notice/${uuid}/`;
export const NOTICEEDIT = (uuid: string) => `/notice/${uuid}/edit/`;
export const FEEDBACKS = "/feedback/";
export const ITEMS = "/items/";
export const ITEMADD = "/item/add/";
export const ITEMEDIT = (uuid: string) => `/item/${uuid}/edit/`;
export const INVOICES = "/invoices/";
export const INVOICEADD = "/invoice/add/";
export const INVOICEDETAIL = (uuid: string) => `/invoice/${uuid}/`;
export const EDITINVOICE = (uuid: string) => `/invoice/${uuid}/edit/`;
export const RECORDPAYMENT = (uuid: string) => `/invoice/${uuid}/payment/`;
export const STUDENTINVOICES = `/invoice/student/`;
export const RECURRINGINVOICES = "/recurring-invoices/";
export const RECURRINGINVOICEADD = "/recurring-invoice/add/";
export const RECURRINGINVOICEDETAIL = (uuid: string) =>
  `/recurring-invoice/${uuid}/`;
export const EDITRECURRINGINVOICE = (uuid: string) =>
  `/recurring-invoice/${uuid}/edit/`;
