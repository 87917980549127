class Auth {
  authenticated: boolean;
  constructor() {
    this.authenticated = false;
  }
  isAuthenticated() {
    this.authenticated = !!localStorage.getItem("token");
    return this.authenticated;
  }
}

export default new Auth();
