import Http from "./Http";

const http = new Http();

export default class GradeServices {
  static getGrades(filter: any) {
    return http.get<any>({
      endpoint: `/grade/`,
      payload: { params: filter },
    });
  }
  static getGrade(uuid: string) {
    return http.get<any>({
      endpoint: `/grade/${uuid}/`,
    });
  }
  static createGrade(args: any) {
    return http.post<any>({
      endpoint: `/grade/`,
      payload: args,
    });
  }

  static editGrade(uuid: string, args: any) {
    return http.patch<any>({
      endpoint: `/grade/${uuid}/`,
      payload: args,
    });
  }

  static getGradeSection(filter: any) {
    return http.get<any>({
      endpoint: `/grade/grade-section/`,
      payload: { params: filter },
    });
  }
  static getGradeSectionStudents(uuid: string, filter: any) {
    return http.get<any>({
      endpoint: `/grade/grade-section/${uuid}/students/`,
      payload: { params: filter },
    });
  }
  static enrollStudents(uuid: string, args: any) {
    return http.post<any>({
      endpoint: `/grade/grade-section/${uuid}/student-enrollment/`,
      payload: args,
    });
  }
}
