import React from "react";
import { Grid, Skeleton } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    main: {
      padding: "10%",
    },
  })
);
const LandingSkeleton = () => {
  const styles = useStyles();
  return (
    <div>
      <Grid container spacing={2} className={styles["main"]}>
        <Grid item xs={6}>
          <Skeleton variant="rectangular" width={600} height={40} />
          <br />
          <Skeleton variant="rectangular" width={600} height={20} />
          <br />
          <Skeleton variant="rectangular" width={600} height={200} />
        </Grid>
        <Grid item xs={6}>
          <Skeleton variant="rectangular" width={600} height={40} />
          <br />
          <Skeleton variant="rectangular" width={600} height={40} />
          <br />
          <Skeleton variant="rectangular" width={600} height={40} />
          <br />
          <Skeleton variant="rectangular" width={600} height={40} />
          <br />
          <Skeleton variant="rectangular" width={600} height={40} />
        </Grid>
      </Grid>
    </div>
  );
};

export default LandingSkeleton;
