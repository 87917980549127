import * as path from "../routes/constants";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import GroupIcon from "@mui/icons-material/Group";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import SchoolIcon from "@mui/icons-material/School";
import FeedIcon from "@mui/icons-material/Feed";
import EventIcon from "@mui/icons-material/Event";
import AssignmentIcon from "@mui/icons-material/Assignment";
import QuizIcon from "@mui/icons-material/Quiz";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import InfoIcon from "@mui/icons-material/Info";
import FeedbackIcon from "@mui/icons-material/Feedback";

interface ISubItems {
  title: string;
  link: string;
}

interface IProps {
  title: string;
  link: string;
  icon: any;
  subItems?: ISubItems[];
}
export const pages: IProps[] = [
  {
    title: "Notice",
    link: path.NOTICE,
    icon: InfoIcon,
  },
  {
    title: "Academic Session",
    link: path.ACADEMICSESSION,
    icon: SchoolIcon,
  },
  {
    title: "Courses",
    link: path.COURSES,
    icon: MenuBookIcon,
  },
  {
    title: "Teachers",
    link: path.TEACHERS,
    icon: GroupIcon,
  },
  {
    title: "Student",
    link: path.STUDENTS,
    icon: AccountBoxIcon,
  },
  {
    title: "Grades",
    link: path.GRADES,
    icon: QuizIcon,
    subItems: [
      { title: "Class", link: path.GRADES },
      { title: "Section", link: path.SECTIONS },
      { title: "Exam Terms", link: path.EXAMTERM },
    ],
  },
  {
    title: "Events",
    link: path.EVENTS,
    icon: EventIcon,
  },
  {
    title: "Billing",
    link: path.BILLS,
    icon: AccountBalanceWalletIcon,
    subItems: [
      {
        title: "Items",
        link: path.ITEMS,
      },
      {
        title: "Invoices",
        link: path.INVOICES,
      },
      {
        title: "Student Record",
        link: path.STUDENTINVOICES,
      },
      {
        title: "Recurring Invoice",
        link: path.RECURRINGINVOICES,
      },
    ],
  },
  {
    title: "Leave Applications",
    link: path.LEAVE,
    icon: FeedIcon,
  },
  {
    title: "Attendance",
    link: path.ATTENDANCE,
    icon: CalendarMonthIcon,
  },
  {
    title: "Assignment",
    link: path.ASSIGNMENT,
    icon: AssignmentIcon,
  },
  {
    title: "Library",
    link: "Library",
    icon: LibraryBooksIcon,
    subItems: [
      {
        title: "Genre",
        link: path.GENRE,
      },
      {
        title: "Book",
        link: path.BOOK,
      },
      {
        title: "Lend Books",
        link: path.BOOKLEND,
      },
      {
        title: "Return Books",
        link: path.BOOKRETURN,
      },
    ],
  },
  {
    title: "Feedback",
    link: path.FEEDBACKS,
    icon: FeedbackIcon,
  },
];
