import React, { useEffect, Suspense } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import _ from "lodash";
import { useCurrentUser } from "../../../core/context/CurrentUser";
import auth from "../../../core/config/auth";
import User from "../../../core/services/User";
import { LOGIN } from "../../../core/routes/constants";
import SideBar from "../../NavBar/SideBar";
import { useGradesContext } from "../../../core/context/GradeContext";
import GradeServices from "../../../core/services/Grade";

interface IAuthenticatedProps {
  element: any;
  roles: string[];
  fallback: any;
}
const AuthenticatedRoute = (props: IAuthenticatedProps) => {
  const { element, roles, fallback } = props;
  const { currentUser, setValues } = useCurrentUser();
  const { grades, setGradeValues } = useGradesContext();
  const isAuthed = auth.isAuthenticated();
  let group: string = currentUser?.groups?.[0];
  const navigate = useNavigate();

  useEffect(() => {
    if (grades?.length <= 0) {
      GradeServices.getGrades({}).then((response) => {
        setGradeValues(response?.results);
      });
    }
    //eslint-disable-next-line
  }, [grades]);

  useEffect(() => {
    if (isAuthed && !currentUser && !group) {
      User.getCurrentUser()
        .then((response) => {
          setValues(response);
        })
        .catch(() => {
          navigate(LOGIN);
        });
    }
    //eslint-disable-next-line
  }, [currentUser, isAuthed, navigate, group]);

  if (isAuthed && _.isEmpty(currentUser)) {
    return null;
  }

  const userHasRequiredRole = isAuthed && roles && roles.includes(group);

  return (
    <React.Fragment>
      <SideBar>
        <Suspense fallback={fallback}>
          {userHasRequiredRole ? <>{element}</> : <Navigate to={LOGIN} />}
        </Suspense>
      </SideBar>
    </React.Fragment>
  );
};

export default AuthenticatedRoute;
