import { makeStyles, createStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import * as colors from "../../core/constants/colors";

const loginStyles = makeStyles((theme: Theme) =>
  createStyles({
    login: {
      margin: "8% auto",
      width: "100%",
    },

    loginCard: {
      width: "30%",
      margin: "auto",
      padding: "1% 2%",
      border: `1px solid ${colors.primary_main}`,
      borderRadius: "8px",
      [theme.breakpoints.down("lg")]: {
        width: "60%",
      },
      [theme.breakpoints.down("md")]: {
        width: "90%",
      },
    },
  })
);

export { loginStyles };
