import React from "react";
import { Skeleton } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

interface IProps {
  navBar?: boolean;
}
const useStyles = makeStyles((theme) =>
  createStyles({
    main: {
      padding: "3% 10%",
    },
    title: {
      display: "flex",
      justifyContent: "center",
      marginBottom: "2%",
    },
    topBar: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "2%",
    },
    table: {
      border: "1px solid #a8a8a8",
    },
    emptyDiv: {
      height: "40px",
    },
  })
);
const ListPagesSkeleton = (props: IProps) => {
  const styles = useStyles();
  const { navBar = true } = props;
  return (
    <>
      {navBar && <Skeleton variant="rectangular" width={"100%"} height={60} />}
      <div className={styles["main"]}>
        <div className={styles.title}>
          <Skeleton
            variant="rectangular"
            animation={"wave"}
            width={300}
            height={40}
          />
        </div>

        <div className={styles.topBar}>
          <Skeleton
            variant="rectangular"
            animation={"wave"}
            width={600}
            height={40}
          />
          <Skeleton
            variant="rectangular"
            animation={"wave"}
            width={150}
            height={40}
          />
        </div>
        {Array.from(Array(13)).map((skeleton: any, index) => (
          <div className={styles.table} key={index}>
            {index % 2 === 0 ? (
              <Skeleton
                variant="rectangular"
                animation={"wave"}
                width={"100%"}
                height={40}
              />
            ) : (
              <div className={styles.emptyDiv} />
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default ListPagesSkeleton;
