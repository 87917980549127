import React, { Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import FormPagesSkeleton from "../../components/Skeleton/FormPages";
import AuthenticatedRoute from "../../components/Routes/Authenticated";
import NavBar from "../../components/NavBar";
import Login from "../../pages/Login";
import auth from "../config/auth";
import { LANDING, LOGIN } from "./constants";
import component_route from "./component_route";
import NotFound from "../../components/NotFound";

const AppRoutes = () => {
  const isAuthed = auth.isAuthenticated();

  return (
    <Routes>
      <Route path={"*"} element={<NotFound />} />
      <Route
        path={LOGIN}
        caseSensitive
        element={
          !isAuthed ? (
            <Suspense fallback={<FormPagesSkeleton />}>
              <NavBar />
              <Login />
            </Suspense>
          ) : (
            <Navigate to={LANDING} replace />
          )
        }
      />

      {component_route &&
        component_route.map((route, index) => (
          <React.Fragment key={index}>
            <Route
              path={route.path}
              element={
                <AuthenticatedRoute
                  roles={route.role}
                  element={<route.element />}
                  fallback={<route.fallback />}
                />
              }
            />
          </React.Fragment>
        ))}
    </Routes>
  );
};
export default AppRoutes;
