import React from "react";
import { createTheme, ThemeProvider } from "@mui/material";
import { BrowserRouter } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import AppRoutes from "./core/routes/route";
import { CurrentUserProvider } from "./core/context/CurrentUser";
import { toastStyle } from "./core/config/toaststyle";
import "./App.css";
import { GradeContextProvider } from "./core/context/GradeContext";

const theme = createTheme({
  palette: {
    primary: {
      main: "#116466",
      dark: "#123C69",
    },
    secondary: {
      main: "#BAB2B5",
      light: "#A8A8A8",
      dark: "#808080",
    },
  },
  typography: {
    fontFamily: ["Sora", "Roboto", "sans-serif"].join(","),
  },
});

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <CurrentUserProvider>
          <GradeContextProvider>
            <BrowserRouter>
              <AppRoutes />
            </BrowserRouter>
          </GradeContextProvider>
        </CurrentUserProvider>
      </ThemeProvider>
      <Toaster position="bottom-center" toastOptions={toastStyle} />
    </div>
  );
}

export default App;
