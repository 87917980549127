import React, { createContext, useState, useContext, ReactChild } from "react";

import { IUser } from "../interfaces/IUser";

const CurrentUser = createContext({});

interface IProps {
  children: ReactChild;
}

export const CurrentUserProvider = ({ children }: IProps) => {
  const [currentUser, setCurrentUser] = useState<IUser>();

  const setValues = (args: IUser) => {
    setCurrentUser(args);
  };

  return (
    <CurrentUser.Provider
      value={{
        currentUser,
        setValues,
      }}
    >
      {children}
    </CurrentUser.Provider>
  );
};

export const useCurrentUser: any = () => useContext(CurrentUser);
