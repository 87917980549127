import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { useCurrentUser } from "../../core/context/CurrentUser";
import { LANDING, LOGIN } from "../../core/routes/constants";
import { removeToken } from "../../core/config/token";
import config from "../../core/config";
import auth from "../../core/config/auth";

const NavBar = () => {
  const { currentUser, setValues } = useCurrentUser();
  const navigate = useNavigate();
  const isAuthed = auth.isAuthenticated();
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleProfileView = () => {
    setAnchorElUser(null);
    navigate(`/my-profile`);
  };

  const handleLogout = () => {
    removeToken({ name: config.tokenName });
    setValues({
      username: "",
      first_name: "",
      middle_name: "",
      last_name: "",
      groups: [],
      profile_picture: "",
      email: "",
      display_name: "",
    });
    navigate(LANDING);
  };

  const handleLogin = () => {
    navigate(LOGIN);
  };

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{
              mr: 2,
              display: { xs: "flex" },
              cursor: "pointer",
            }}
            onClick={() => navigate(LANDING)}
          >
            School MS
          </Typography>
          <Box sx={{ flexGrow: 0 }}>
            {!window.location.pathname.includes("login") && (
              <>
                {isAuthed && currentUser ? (
                  <Tooltip title="Open settings">
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                      {currentUser?.profile_pictue ? (
                        <Avatar
                          alt="Remy Sharp"
                          src="/static/images/avatar/2.jpg"
                        />
                      ) : (
                        <Avatar>
                          {currentUser.username?.[0].toUpperCase()}
                        </Avatar>
                      )}
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Button
                    sx={{
                      my: 2,
                      color: "white",
                      display: "block",
                      border: "1px solid white",
                      padding: "5px 2rem",
                    }}
                    onClick={handleLogin}
                  >
                    LogIn
                  </Button>
                )}
              </>
            )}

            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem onClick={handleProfileView}>
                <Typography textAlign="center">Profile</Typography>
              </MenuItem>
              <MenuItem onClick={handleLogout}>
                <Typography textAlign="center">Logout</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default NavBar;
