import * as yup from "yup";

export const loginValidation = yup.object().shape({
  username: yup.string().required("You must specify username."),
  password: yup.string().required("You must specify password."),
});

export const newUserValidation = yup.object({
  username: yup.string().required("You must specify username."),
  password: yup.string().required("Password is required"),
  repeat_password: yup
    .string()
    .required("Password is required")
    .oneOf([yup.ref("password"), null], "Passwords must match."),
});

export const newStudentValidation = yup.object({
  first_name: yup
    .string()
    .required("You must specify first name.")
    .max(150, "First name cannot be more than 150 characters."),
  last_name: yup
    .string()
    .required("You must specify last name.")
    .max(150, "Last name cannot be more than 150 characters."),
  birthdate: yup.string().required("You must specify birthdate."),
  temporary_address: yup
    .string()
    .required("You must specify temporary address.")
    .max(200, "Temporary address cannot be more than 200 characters."),
  permanent_address: yup
    .string()
    .required("You must specify permanent address.")
    .max(200, "Permanent address cannot be more than 200 characters."),
  father_mobile_number: yup
    .string()
    .required("You must specify father's phone number.")
    .max(14, "Phone number cannot be more than 14 digits."),
  father_name: yup
    .string()
    .required("You must specify father's name")
    .max(100, "Name cannot be more than 100 characters"),
  mother_mobile_number: yup
    .string()
    .required("You must specify mother's phone number.")
    .max(14, "Phone number cannot be more than 14 digits."),
  mother_name: yup
    .string()
    .required("You must specify mother's name")
    .max(100, "Name cannot be more than 100 characters"),
  grade: yup.object().shape({
    label: yup.string().required("You must specify the grade."),
    value: yup.string().required("You must specify the grade."),
  }),
  gender: yup.object().shape({
    label: yup.string().required("You must specify the gender."),
    value: yup.string().required("You must specify the gender."),
  }),
  religion: yup.object().shape({
    label: yup.string().required("You must specify the religion."),
    value: yup.string().required("You must specify the religion."),
  }),
  blood_group: yup.object().shape({
    label: yup.string().required("You must specify the blood group."),
    value: yup.string().required("You must specify the blood group."),
  }),
  nationality: yup.object().shape({
    label: yup.string().required("You must specify the nationality."),
    value: yup.string().required("You must specify the nationality."),
  }),
});

export const newTeacherValidation = yup.object({
  first_name: yup
    .string()
    .required("You must specify first name.")
    .max(150, "First name cannot be more than 150 characters."),
  last_name: yup
    .string()
    .required("You must specify last name.")
    .max(150, "Last name cannot be more than 150 characters."),
  temporary_address: yup
    .string()
    .required("You must specify temporary address.")
    .max(200, "Temporary address cannot be more than 200 characters."),
  permanent_address: yup
    .string()
    .required("You must specify permanent address.")
    .max(200, "Permanent address cannot be more than 200 characters."),
  phone_number: yup
    .string()
    .required("You must specify phone number.")
    .max(14, "Phone number cannot be more than 14 digits."),
  email: yup.string().required("You must specify email address."),
  experience: yup.string().required("You must specify experience."),
  university: yup.string().required("You must specify university."),
  education: yup.string().required("You must specify education."),
  gender: yup.object().shape({
    label: yup.string().required("You must specify the gender."),
    value: yup.string().required("You must specify the gender."),
  }),
});

export const profileValidation = yup.object().shape({
  email: yup
    .string()
    .email("Invalid Email Address.")
    .max(50, "Email cannot be more than 50 characters")
    .required("You must specify email."),
  first_name: yup
    .string()
    .max(45, "First Name must not have more than 45 characters.")
    .required("You must specify first name."),
  last_name: yup
    .string()
    .max(45, "Last Name must not have more than 45 characters.")
    .required("You must specify last name."),
});
