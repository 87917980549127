import React, { useEffect, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { InputAdornment, TextField } from "@mui/material";

import { useStyles } from "./styles";

interface IProps {
  title: string;
  placeholder: string;
  validation?: any;
  register: any;
  icon?: any;
  required?: boolean;
  type?: string;
  defaultValue?: string;
  multiline?: boolean;
  rows?: number;
}

const FormInput = (props: IProps) => {
  const {
    title,
    placeholder,
    validation,
    register,
    icon,
    type,
    required = false,
    defaultValue,
    multiline = false,
    rows = 4,
  } = props;
  const [show, setShow] = useState<boolean>(false);
  const [formType, setFormType] = useState<string>("");
  const [inputProps, setInputProps] = useState({});
  const styles = useStyles();

  useEffect(() => {
    if (type) {
      if (type === "password") {
        setInputProps({
          endAdornment: (
            <InputAdornment
              position="end"
              className={styles.passwordVisibility}
              onClick={() => {
                setShow(!show);
              }}
            >
              {show ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </InputAdornment>
          ),
        });
        if (show) {
          setFormType("text");
        } else {
          setFormType("password");
        }
      } else {
        setFormType(type);
      }
    } else {
      setFormType("text");
    }
  }, [show, type, title, icon, styles.passwordVisibility]);

  return (
    <div>
      <TextField
        label={placeholder}
        name={title}
        id={`id-${title}`}
        fullWidth
        InputProps={inputProps}
        variant="outlined"
        type={formType}
        autoComplete="off"
        {...register(title, validation)}
        defaultValue={defaultValue}
        required={required}
        multiline={multiline}
        rows={rows}
      />
    </div>
  );
};

export default FormInput;
