import { lazy } from "react";
import LandingSkeleton from "../../components/Skeleton/Landing";
import ListPagesSkeleton from "../../components/Skeleton/ListPages";
import FormPagesSkeleton from "../../components/Skeleton/FormPages";
import config from "../config";
import * as path from "./constants";

const admin = [config.roles.Admin];
// const student = [config.roles.Student];
// const teacher = [config.roles.Teacher];
const common = [config.roles.Teacher, config.roles.Student, config.roles.Admin];

interface IProps {
  path: string;
  exact?: boolean;
  role: string[];
  fallback: any;
  element: any;
}
const component_route: IProps[] = [
  {
    path: path.LANDING,
    exact: true,
    role: common,
    fallback: LandingSkeleton,
    element: lazy(() => import("../../pages/Landing")),
  },
  {
    path: path.COURSES,
    exact: true,
    role: admin,
    fallback: ListPagesSkeleton,
    element: lazy(() => import("../../pages/Course")),
  },
  {
    path: path.PROFILE,
    exact: true,
    role: admin,
    fallback: LandingSkeleton,
    element: lazy(() => import("../../pages/Profile")),
  },
  {
    path: path.EDITPROFILE(":uuid"),
    exact: true,
    role: admin,
    fallback: FormPagesSkeleton,
    element: lazy(() => import("../../pages/Profile/ProfileEdit")),
  },
  {
    path: path.ADDCOURSE,
    exact: true,
    role: admin,
    fallback: FormPagesSkeleton,
    element: lazy(() => import("../../pages/Course/CourseAdd")),
  },
  {
    path: path.EDITCOURSE(":uuid"),
    exact: true,
    role: admin,
    fallback: FormPagesSkeleton,
    element: lazy(() => import("../../pages/Course/CourseEdit")),
  },
  {
    path: path.ADDSTUDENT,
    exact: true,
    role: admin,
    fallback: FormPagesSkeleton,
    element: lazy(() => import("../../pages/Student/StudentAdd")),
  },
  {
    path: path.STUDENTS,
    exact: true,
    role: admin,
    fallback: ListPagesSkeleton,
    element: lazy(() => import("../../pages/Student")),
  },
  {
    path: path.EDITSTUDENT(":uuid"),
    exact: true,
    role: admin,
    fallback: FormPagesSkeleton,
    element: lazy(() => import("../../pages/Student/StudentEdit")),
  },
  {
    path: path.STUDENTDETAIL(":uuid"),
    exact: true,
    role: admin,
    fallback: FormPagesSkeleton,
    element: lazy(() => import("../../pages/Student/StudentDetail")),
  },
  {
    path: path.ADDTEACHER,
    exact: true,
    role: admin,
    fallback: FormPagesSkeleton,
    element: lazy(() => import("../../pages/Teacher/TeacherAdd")),
  },
  {
    path: path.TEACHERS,
    exact: true,
    role: admin,
    fallback: ListPagesSkeleton,
    element: lazy(() => import("../../pages/Teacher")),
  },
  {
    path: path.TEACHERDETAIL(":uuid"),
    exact: true,
    role: admin,
    fallback: FormPagesSkeleton,
    element: lazy(() => import("../../pages/Teacher/TeacherDetail")),
  },
  {
    path: path.EDITTEACHER(":uuid"),
    exact: true,
    role: admin,
    fallback: FormPagesSkeleton,
    element: lazy(() => import("../../pages/Teacher/TeacherEdit")),
  },
  {
    path: path.ADDGRADE,
    exact: true,
    role: admin,
    fallback: FormPagesSkeleton,
    element: lazy(() => import("../../pages/Grade/GradeAdd")),
  },
  {
    path: path.GRADES,
    exact: true,
    role: admin,
    fallback: ListPagesSkeleton,
    element: lazy(() => import("../../pages/Grade")),
  },
  {
    path: path.GRADEROUTINE(":uuid"),
    exact: true,
    role: admin,
    fallback: ListPagesSkeleton,
    element: lazy(() => import("../../pages/Routine")),
  },
  {
    path: path.ADDSECTION,
    exact: true,
    role: admin,
    fallback: FormPagesSkeleton,
    element: lazy(() => import("../../pages/Section/SectionAdd")),
  },
  {
    path: path.SECTIONS,
    exact: true,
    role: admin,
    fallback: ListPagesSkeleton,
    element: lazy(() => import("../../pages/Section")),
  },
  {
    path: path.ADDGRADEROUTINE(":uuid"),
    exact: true,
    role: admin,
    fallback: FormPagesSkeleton,
    element: lazy(() => import("../../pages/Routine/RoutineAdd")),
  },
  {
    path: path.EDITROUTINE(":uuid", ":routineUuid"),
    exact: true,
    role: admin,
    fallback: FormPagesSkeleton,
    element: lazy(() => import("../../pages/Routine/RoutineEdit")),
  },
  {
    path: path.ADDEXAMROUTINE(":uuid"),
    exact: true,
    role: admin,
    fallback: FormPagesSkeleton,
    element: lazy(() => import("../../pages/ExamRoutine/ExamRoutineAdd")),
  },
  {
    path: path.EXAMROUTINE(":uuid"),
    exact: true,
    role: admin,
    fallback: ListPagesSkeleton,
    element: lazy(() => import("../../pages/ExamRoutine")),
  },
  {
    path: path.EDITEXAMROUTINE(":uuid", ":routineUuid"),
    exact: true,
    role: admin,
    fallback: FormPagesSkeleton,
    element: lazy(() => import("../../pages/ExamRoutine/ExamRoutineEdit")),
  },
  {
    path: path.EVENTS,
    exact: true,
    role: admin,
    fallback: ListPagesSkeleton,
    element: lazy(() => import("../../pages/Event")),
  },
  {
    path: path.LEAVE,
    exact: true,
    role: admin,
    fallback: ListPagesSkeleton,
    element: lazy(() => import("../../pages/Leave")),
  },
  {
    path: path.ADDEVENT,
    exact: true,
    role: admin,
    fallback: FormPagesSkeleton,
    element: lazy(() => import("../../pages/Event/AddEvent")),
  },
  {
    path: path.EDITEVENT(":uuid"),
    exact: true,
    role: admin,
    fallback: FormPagesSkeleton,
    element: lazy(() => import("../../pages/Event/EditEvent")),
  },
  {
    path: path.ACADEMICSESSION,
    exact: true,
    role: admin,
    fallback: ListPagesSkeleton,
    element: lazy(() => import("../../pages/AcademicSession")),
  },
  {
    path: path.EXAMTERM,
    exact: true,
    role: admin,
    fallback: ListPagesSkeleton,
    element: lazy(() => import("../../pages/ExamTerm")),
  },
  {
    path: path.ADDEXAMTERM,
    exact: true,
    role: admin,
    fallback: FormPagesSkeleton,
    element: lazy(() => import("../../pages/ExamTerm/ExamTermAdd")),
  },
  {
    path: path.ATTENDANCE,
    exact: true,
    role: admin,
    fallback: ListPagesSkeleton,
    element: lazy(() => import("../../pages/Attendance")),
  },
  {
    path: path.CLASSATTENDANCE(":uuid"),
    exact: true,
    role: admin,
    fallback: ListPagesSkeleton,
    element: lazy(() => import("../../pages/Attendance/ClassAttendance")),
  },
  {
    path: path.ASSIGNMENT,
    exact: true,
    role: admin,
    fallback: ListPagesSkeleton,
    element: lazy(() => import("../../pages/Assignment")),
  },
  {
    path: path.GENRE,
    exact: true,
    role: admin,
    fallback: ListPagesSkeleton,
    element: lazy(() => import("../../pages/Library/Genre")),
  },
  {
    path: path.BOOK,
    exact: true,
    role: admin,
    fallback: ListPagesSkeleton,
    element: lazy(() => import("../../pages/Library/Book")),
  },
  {
    path: path.BOOKADD,
    exact: true,
    role: admin,
    fallback: FormPagesSkeleton,
    element: lazy(() => import("../../pages/Library/Book/BookAdd")),
  },
  {
    path: path.BORROWERSLIST(":uuid"),
    exact: true,
    role: admin,
    fallback: ListPagesSkeleton,
    element: lazy(() => import("../../pages/Library/Book/BorrowersList")),
  },
  {
    path: path.BOOKEDIT(":uuid"),
    exact: true,
    role: admin,
    fallback: FormPagesSkeleton,
    element: lazy(() => import("../../pages/Library/Book/BookEdit")),
  },
  {
    path: path.BOOKLEND,
    exact: true,
    role: admin,
    fallback: ListPagesSkeleton,
    element: lazy(() => import("../../pages/Library/Book/LendBook")),
  },
  {
    path: path.BOOKRETURN,
    exact: true,
    role: admin,
    fallback: ListPagesSkeleton,
    element: lazy(() => import("../../pages/Library/Book/ReturnBook")),
  },
  {
    path: path.NOTICE,
    exact: true,
    role: admin,
    fallback: ListPagesSkeleton,
    element: lazy(() => import("../../pages/Notice")),
  },
  {
    path: path.NOTICEADD,
    exact: true,
    role: admin,
    fallback: FormPagesSkeleton,
    element: lazy(() => import("../../pages/Notice/NoticeAdd")),
  },
  {
    path: path.NOTICEDETAIL(":uuid"),
    exact: true,
    role: admin,
    fallback: ListPagesSkeleton,
    element: lazy(() => import("../../pages/Notice/NoticeDetail")),
  },
  {
    path: path.NOTICEEDIT(":uuid"),
    exact: true,
    role: admin,
    fallback: FormPagesSkeleton,
    element: lazy(() => import("../../pages/Notice/NoticeEdit")),
  },
  {
    path: path.FEEDBACKS,
    exact: true,
    role: admin,
    fallback: ListPagesSkeleton,
    element: lazy(() => import("../../pages/Feedback")),
  },
  {
    path: path.ITEMS,
    exact: true,
    role: admin,
    fallback: ListPagesSkeleton,
    element: lazy(() => import("../../pages/Bill/Item")),
  },
  {
    path: path.ITEMADD,
    exact: true,
    role: admin,
    fallback: FormPagesSkeleton,
    element: lazy(() => import("../../pages/Bill/Item/ItemAdd")),
  },
  {
    path: path.ITEMEDIT(":uuid"),
    exact: true,
    role: admin,
    fallback: FormPagesSkeleton,
    element: lazy(() => import("../../pages/Bill/Item/ItemEdit")),
  },
  {
    path: path.INVOICES,
    exact: true,
    role: admin,
    fallback: ListPagesSkeleton,
    element: lazy(() => import("../../pages/Bill/Invoices")),
  },
  {
    path: path.INVOICEADD,
    exact: true,
    role: admin,
    fallback: FormPagesSkeleton,
    element: lazy(() => import("../../pages/Bill/Invoices/InvoiceAdd")),
  },
  {
    path: path.INVOICEDETAIL(":uuid"),
    exact: true,
    role: admin,
    fallback: ListPagesSkeleton,
    element: lazy(() => import("../../pages/Bill/Invoices/InvoiceDetail")),
  },
  {
    path: path.EDITINVOICE(":uuid"),
    exact: true,
    role: admin,
    fallback: FormPagesSkeleton,
    element: lazy(() => import("../../pages/Bill/Invoices/InvoiceEdit")),
  },
  {
    path: path.RECORDPAYMENT(":uuid"),
    exact: true,
    role: admin,
    fallback: FormPagesSkeleton,
    element: lazy(() => import("../../pages/Bill/PaymentRecord")),
  },
  {
    path: path.STUDENTINVOICES,
    exact: true,
    role: admin,
    fallback: ListPagesSkeleton,
    element: lazy(() => import("../../pages/Bill/StudentRecord")),
  },
  {
    path: path.RECURRINGINVOICES,
    exact: true,
    role: admin,
    fallback: ListPagesSkeleton,
    element: lazy(() => import("../../pages/Bill/RecurringInvoices")),
  },
  {
    path: path.RECURRINGINVOICEADD,
    exact: true,
    role: admin,
    fallback: FormPagesSkeleton,
    element: lazy(
      () => import("../../pages/Bill/RecurringInvoices/RecurringInvoiceAdd")
    ),
  },
  {
    path: path.RECURRINGINVOICEDETAIL(":uuid"),
    exact: true,
    role: admin,
    fallback: ListPagesSkeleton,
    element: lazy(
      () => import("../../pages/Bill/RecurringInvoices/RecurringInvoiceDetail")
    ),
  },
  {
    path: path.EDITRECURRINGINVOICE(":uuid"),
    exact: true,
    role: admin,
    fallback: FormPagesSkeleton,
    element: lazy(
      () => import("../../pages/Bill/RecurringInvoices/RecurringInvoiceEdit")
    ),
  },
];

export default component_route;
