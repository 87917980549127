import { Theme } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";

export const useStyles: any = makeStyles((theme: Theme) =>
  createStyles({
    passwordVisibility: {
      "&:hover": {
        cursor: "pointer",
      },
    },
  })
);
