import React from "react";
import Button from "@mui/material/Button";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import "./Button.scss";

interface IButton {
  children: any;
  type?: "submit" | "button";
  classType?: "submit" | "add" | "cancel" | "normal";
  variant?: "outlined" | "text" | "contained";
  onClick?: () => void;
  disabled?: boolean;
  width?: string;
  height?: string;
  color?: "primary" | "secondary" | "error";
}

const CustomButton = (props: IButton) => {
  const {
    children,
    variant = "contained",
    type = "button",
    classType = "normal",
    onClick,
    disabled,
    width = "40%",
    height = "45px",
    color = "primary",
  } = props;

  return (
    <Button
      type={type}
      variant={variant}
      className={`custom-button ${classType}`}
      onClick={onClick}
      disabled={disabled}
      style={{ width: width, height: height }}
      color={color}
    >
      {classType === "add" && <AddCircleIcon />} &nbsp;
      {children}
    </Button>
  );
};

export default CustomButton;
