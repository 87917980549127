import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { yupResolver } from "@hookform/resolvers/yup";
import IconButton from "@mui/material/IconButton";
import LoginIcon from "@mui/icons-material/Login";
import Typography from "@mui/material/Typography";
import CustomTextField from "../../components/TextInputField";
import CustomButton from "../../components/Button";
import { loginValidation } from "../../core/validations/loginValidation";
import User from "../../core/services/User";
import { removeToken, setToken } from "../../core/config/token";
import config from "../../core/config";
import { LANDING } from "../../core/routes/constants";
import { useCurrentUser } from "../../core/context/CurrentUser";
import { loginStyles } from "./Login";

const Login = () => {
  const styles = loginStyles();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(loginValidation),
  });
  const [saving, setSaving] = useState<boolean>(false);
  const navigate = useNavigate();
  const { setValues } = useCurrentUser();

  const submitData = (data: any) => {
    setSaving(true);
    User.login(data)
      .then((response) => {
        if (response.token) {
          const group = response?.groups?.[0];
          if (group === "ADMIN") {
            setToken({
              name: config.tokenName,
              value: JSON.stringify(response.token),
            });
            toast.success("Login Successful!");
            User.getCurrentUser()
              .then((response) => {
                setValues(response?.data);
              })
              .catch(() => {})
              .finally(() => {
                navigate(LANDING);
                setSaving(false);
              });
          } else {
            toast.error(
              "You must be an admin to login. " +
                "Please try again with correct credentials."
            );
            setSaving(false);
            removeToken({ name: config.tokenName });
          }
        } else {
          toast.error("Login Failed");
          setSaving(false);
        }
      })
      .catch((response) => {
        toast.error(
          response?.data?.non_field_errors
            ? response?.data?.non_field_errors[0]
            : response?.data?.detail
            ? response?.data?.detail[0]
            : "Couldn't Login.Please try again."
        );
        setSaving(false);
      });
  };
  return (
    <div className={styles["login"]}>
      <div className={styles.loginCard}>
        <Typography variant="h6">Please Login Here</Typography>
        <br />
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          color="inherit"
        >
          <LoginIcon sx={{ fontSize: 80 }} />
        </IconButton>
        <br />
        <br />

        <form onSubmit={handleSubmit(submitData)}>
          <CustomTextField
            placeholder="Username"
            register={register}
            title="username"
            required
          />
          <br />
          {errors?.username && (
            <span className="error-message">{errors?.username.message}</span>
          )}
          <br />
          <CustomTextField
            placeholder="Password"
            register={register}
            title="password"
            type="password"
            required
          />
          <br />
          {errors?.password && (
            <span className="error-message">{errors?.password.message}</span>
          )}
          <br />
          <CustomButton
            variant="contained"
            width="100%"
            disabled={saving}
            type="submit"
          >
            Login
          </CustomButton>
        </form>
      </div>
    </div>
  );
};

export default Login;
