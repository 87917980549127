import { makeStyles, createStyles } from "@mui/styles";
import { Theme } from "@mui/material";

const notFoundStyles = makeStyles((theme: Theme) =>
  createStyles({
    notFound: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100vh",
    },
    notFoundPaper: {
      padding: "5% 15%",
      borderRadius: "8px",
    },
    noDataPicture: {
      display: "block",
      margin: "0 auto",
      width: "400px",
    },
    links: {
      color: theme.palette.secondary.light,
    },
    link: {
      textDecoration: "underline",
      marginRight: "20px",
      cursor: "pointer",
    },
  })
);

export { notFoundStyles };
