import React from "react";
import { Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import noData from "../../assets/svg/404.svg";
import { COURSES, GRADES, LANDING } from "../../core/routes/constants";
import { notFoundStyles } from "./NotFoundStyles";

const NotFound = () => {
  const classes = notFoundStyles();
  const navigate = useNavigate();

  return (
    <div className={classes.notFound}>
      <Paper className={classes.notFoundPaper} elevation={10}>
        <img
          src={noData}
          alt="No Data Available"
          className={`${classes.noDataPicture}`}
        />
        <br />
        <br />
        <Typography variant="h4" textAlign="center">
          Oops!
        </Typography>
        <Typography variant="subtitle2" textAlign="center">
          We can't seem to find the page you are looking for
        </Typography>
        <br />
        <Typography
          variant="subtitle2"
          textAlign="center"
          className={classes.links}
        >
          Here are some helpful links instead:
        </Typography>
        <Typography
          variant="subtitle2"
          textAlign="center"
          className={classes.links}
        >
          <span className={classes.link} onClick={() => navigate(LANDING)}>
            Home
          </span>
          <span className={classes.link} onClick={() => navigate(COURSES)}>
            Courses
          </span>
          <span className={classes.link} onClick={() => navigate(GRADES)}>
            Grades
          </span>
        </Typography>
      </Paper>
    </div>
  );
};

export default NotFound;
