import React from "react";
import { Grid, Skeleton } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

interface IProps {
  navBar?: boolean;
}
const useStyles = makeStyles((theme) =>
  createStyles({
    main: {
      margin: "7% 20%",
      border: "1px solid #a8a8a8",
      padding: "2%",
      borderRadius: "8px",
    },
    title: {
      display: "flex",
      justifyContent: "center",
      marginBottom: "2%",
    },
    gridItem: {
      marginBottom: "20px",
    },
    buttons: {
      display: "flex",
      justifyContent: "space-evenly",
      marginBottom: "2%",
      padding: "0 15%",
    },
  })
);
const FormPagesSkeleton = (props: IProps) => {
  const styles = useStyles();
  const { navBar = true } = props;
  return (
    <>
      {navBar && <Skeleton variant="rectangular" width={"100%"} height={60} />}
      <div className={styles["main"]}>
        <div className={styles.title}>
          <Skeleton
            variant="rectangular"
            animation={"wave"}
            width={300}
            height={40}
          />
        </div>
        {Array.from(Array(2)).map((skeleton: any, index) => (
          <Grid container spacing={2} key={index} className={styles.gridItem}>
            <Grid item xs={6}>
              <Skeleton
                variant="rectangular"
                animation={"wave"}
                width={"100%"}
                height={50}
              />
            </Grid>
            <Grid item xs={6}>
              <Skeleton
                variant="rectangular"
                animation={"wave"}
                width={"100%"}
                height={50}
              />
            </Grid>
          </Grid>
        ))}
        <Skeleton
          variant="rectangular"
          animation={"wave"}
          width={"100%"}
          height={50}
        />
        <br />
        <Skeleton
          variant="rectangular"
          animation={"wave"}
          width={"100%"}
          height={100}
        />
        <br />
        <div className={styles.buttons}>
          <Skeleton
            variant="rectangular"
            animation={"wave"}
            width={400}
            height={50}
          />
          <Skeleton
            variant="rectangular"
            animation={"wave"}
            width={400}
            height={50}
          />
        </div>
      </div>
    </>
  );
};

export default FormPagesSkeleton;
